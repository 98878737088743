:root {
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  --primary-color3: #ed6f5b;
  --primary-color4: #b04d3d;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: #b04d3d;
  --primary-color9: #b04d3d;

  --default-light-banner-url: url(../images/default-banner.webp);
  --profile-light-banner-url: url(../images/profile-banner.webp);
}

.flat-title-page.inner {
  padding: 200px 0;
}

.header_1.header_2.style2 {
  background: var(--primary-color3) !important;
}

#footer {
  background-color: #221f20 !important;
}

#footer h5,
#footer .mg-t-20 a,
.widget-menu ul li a {
  color: #fff !important;
}

.widget-social.style-1 ul li a,
.menu_card .dropdown-menu a {
  color: #000 !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  background-color: #b04d3d !important;
  border-color: #b04d3d !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  border: 2px solid #fff !important;
}

/* Searchbox */
@media (max-width: 992px) {
  input[type='search'] {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']:focus {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']::placeholder {
    color: var(--primary-color9) !important;
  }

  .header-search-form-container button {
    color: var(--primary-color9) !important;
  }
}

input[type='search']::placeholder {
  color: #fff !important;
}

/* Banner */

.mainslider .swiper-button-prev,
.mainslider .swiper-button-next {
  background: linear-gradient(
    216.56deg,
    #79c3e5 5.32%,
    #8864c4 94.32%
  ) !important;
}

.flat-cart-item .overlay {
  background: var(--primary-color3) !important;
}

.wrap-cart .cart_item.style2 p {
  text-shadow: 2px 2px #0071bc !important;
}
